$(document).ready(function() {
    
    $(window).scroll(function() {
        
        // Sticky header bar
        if($(window).scrollTop() > 0) {
            $('body').addClass('sticky');
        } else if($(window).scrollTop() === 0) {
            $('body').removeClass('sticky');
        }
        
    });
    
    $('.header__burger').click(function() {
        $(this).toggleClass('open');
        $('header').toggleClass('open').find('nav').slideToggle(350);
	});
    
    $('.dropdown .dropdown-menu').on('click', '.dropdown-item', function(e) {
        e.preventDefault();
        var link_text = $(this).html(),
            link_val = $(this).data('value');
        $(this).parent().siblings('button').html(link_text);
        $(this).parents('.dropdown').find('input[type=hidden]').val(link_val);
    });
    
    $('input[type=reset]').on('click', function() {
        $(this).parents('form').find('.dropdown button').html('');
        $(this).parents('form').find('.dropdown input[type=hidden]').val('');        
    });
    
    if($(window).width() <= 600) {
        $('.contact__icons').on('click', 'svg', function() {
            
            var circle = $(this).find('path:first-child');
            
            if(circle.attr('fill') == '#394B5A') {
                $('.contact__icons > div > div').hide();
                $('.contact__icons > div > svg path:first-child').attr('fill', '#394B5A');
                circle.attr('fill', '#4CBBE9');
            } else {
                circle.attr('fill', '#394B5A');
            }
            
            $(this).siblings('div').fadeToggle(); 
            
        });
    }
    
    // Homepage video
    var timeoutId;
    var $videoBgAspect = $(".videobg-aspect");
    var $videoBgWidth = $(".videobg-width");
    
    function videobgEnlarge() {
        var videoAspect = $videoBgAspect.outerHeight() / $videoBgAspect.outerWidth();
        var windowAspect = ($(window).height() / $(window).width());
        if (windowAspect > videoAspect) {
            $videoBgWidth.width((windowAspect / videoAspect) * 100 + '%');
        } else {
            $videoBgWidth.width(100 + "%");
        }
    }

    $(window).resize(function() {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(videobgEnlarge, 100);
    });

    $(function() {
        videobgEnlarge();
    });
    
    $('.property_search__results.map .result--content-wrapper').on('click', 'img', function() {
        $(this).siblings('.result--content').toggleClass('open');
    });
    
    var map_style = [
                {
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#f5f5f5"
                  }
                ]
                },
                {
                "elementType": "labels.icon",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
                },
                {
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#616161"
                  }
                ]
                },
                {
                "elementType": "labels.text.stroke",
                "stylers": [
                  {
                    "color": "#f5f5f5"
                  }
                ]
                },
                {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#bdbdbd"
                  }
                ]
                },
                {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#eeeeee"
                  }
                ]
                },
                {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#757575"
                  }
                ]
                },
                {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#e5e5e5"
                  }
                ]
                },
                {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#9e9e9e"
                  }
                ]
                },
                {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#ffffff"
                  }
                ]
                },
                {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#D2CCC1"
                  }
                ]
                },
                {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#757575"
                  }
                ]
                },                
                {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#D2CCC1"
                  }
                ]
                },
                {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#616161"
                  }
                ]
                },
                {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#D2CCC1"
                  }
                ]
                },
                {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#e5e5e5"
                  }
                ]
                },
                 {
                "featureType": "transit.station",
                "stylers": [
                  {
                    "visibility": "on"
                  }
                ]
                },
                {
                "featureType": "transit.station.bus",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
                },
                {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#c9c9c9"
                  }
                ]
                },
                {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#9e9e9e"
                  }
                ]
                }
            ];
    var map, bounds;
    
    // Google Maps initialisation
    function gmap() {

        var mk, marker, infowindow = new google.maps.InfoWindow(),
            lat = $('#map').data('lat'),
            lng = $('#map').data('lng'),
            zoom = $('#map').data('zoom') ? $('#map').data('zoom') : 14,
            point = {lat: lat, lng: lng};            

        map = new google.maps.Map(document.getElementById('map'), {
            center: point,
            zoom: zoom,
            maxZoom: zoom,
            scrollwheel: false,
            styles: map_style
        });
        
        bounds = new google.maps.LatLngBounds();
        
        // Google maps marker
        function placeMarker(mk) {

            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(mk.lat, mk.lng),
                map: map,
                title: mk.name
            });
            
            // Add infowindow if there is a name and a URL
            if(mk.url && mk.name) {
                google.maps.event.addListener(marker, 'click', function() {
                    infowindow.close();
                    infowindow.setContent('<a href="' + mk.url + '">' + mk.name + '</a>');
                    infowindow.open(map, marker);
                });
            }

            bounds.extend(marker.position);

        }

        // If markers are passed as a data attribute
        if($('#map').data('markers')) {
            
            var m = $('#map').data('markers');            
            for(var i=0; i<m.markers.length; i++) {
                mk = m.markers[i];                
                placeMarker(mk);
            }
            
            map.fitBounds(bounds, 0);
            map.panToBounds(bounds);
            console.log(map.getZoom());
                     
        }
          
        // Refit markers into bounds
        google.maps.event.addListener(map, 'bounds_changed', function() {
            google.maps.event.trigger(map, 'resize');
        });

    }
    
    // Clicking on map view tab
    $('a[aria-controls="map-tab"]').on('click', function() {
        setTimeout(function() {
            gmap();            
            google.maps.event.trigger(map, 'resize');
        }, 200);
    });
    
    // Clicking the "show scheme/property on map" button
    if($('#map > button').length) {        
        $('#map').on('click', '> button', gmap);
        
    // Preload the property search map
    } else if($('#map').is(':visible')) {
        google.maps.event.addDomListener(window, "load", gmap); 
    }
    

    
    new WOW().init();
                     
});